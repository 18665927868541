import { state } from ":mods";
import { EventsDetails, TokenDetails, TokenObject, TokenType } from "./models";
import jwt from "jwt-decode";
import { TOKEN_TYPE } from "./const";
import { batch, createComputed, createMemo, getOwner, runWithOwner } from "solid-js";

export const $token = state.createGlobal<{
  encode: { access: string; refresh: string };
  type: TokenType;
  details: TokenDetails;
}>(undefined);
export const $events = state.createGlobal<{ details: EventsDetails }>(undefined);
export const _token_actions = {
  rm() {
    // TODO: React hookstate clear all store data upon logout
    console.log("logging out");
    $token.set(undefined);
  },
  setJWT(token: TokenObject) {
    batch(() => {
      const details = jwt<TokenDetails>(token.access);
      $token.set({
        encode: token,
        type: undefined,
        details: undefined,
      });
      this.set(details);
    });
  },
  set(details: TokenDetails) {
    // TODO: clean user_type after backend is fixed
    const idx = details.user_type;
    let token_type: TokenType = undefined;
    if (idx === 1) token_type = TOKEN_TYPE.admin;
    if (idx === 2) token_type = TOKEN_TYPE.student;
    if (idx === 3) token_type = TOKEN_TYPE.tutor;
    // else if (idx === 2) level = Level.applicant;
    // else if (idx === 2) user_type = Level.participant;
    // else if (idx === 3) user_type = Level.filterer;
    // else if (idx === 4) level = Level.mentor;
    // else if (idx === 5) level = Level.judge;
    // else if (idx === 4) user_type = Level.judge;
    // else if (idx === 6) user_type = Level.evaluator;
    // else if (idx === 7) user_type = Level.auditor;

    $token.set((s) => ({
      ...s,
      type: token_type,
      details: details,
    }));
  },
};

export const _events_actions = {
  rm() {
    $events.set(undefined);
  },
  set(events: EventsDetails) {
    $events.set({
      details: events,
    });
  },
};

export const events = {
  onDetailsAssigned: (func: (props: TokenDetails) => void) => {
    const owner = getOwner();
    if (!owner) {
      return;
    }
    runWithOwner(owner, () => {
      createComputed(() => {
        const token = $token.value;
        func?.(token.details);
      });
    });
  },
};

export const actions = {
  //? token
  hasToken() {
    const token = $token.unwrap;
    return token !== undefined && token !== null;
  },
  getTokenDetails() {
    const token = $token.unwrap;
    if (!token) return undefined;
    return token.details;
  },
  getTokenType() {
    const token = $token.unwrap;
    if (!token) return undefined;
    return token.type;
  },
  getTokenEncoded() {
    const token = $token.unwrap;
    if (!token) return undefined;
    return token.encode;
  },
  //? events
  getEventsDetails() {
    const events = $events.unwrap;
    if (!events) return undefined;
    return events.details;
  },
};
